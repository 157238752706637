<template>
  <section class="pb-xl">
    <header class="mb-xl">
      <Heading
        :tag="'h1'"
        :level="'h3'"
        class="mb-lg"
        isCapitalized>
        {{ $t('artist.dashboard.bookings.title') }}
      </Heading>
      <div class="artist-dashboard-bookings__header">
        <ul class="artist-dashboard-bookings__booking-types-list">
          <li
            v-for="(bookingType, index) in bookingTypes"
            :key="`booking-type-${index}`"
            class="artist-dashboard-bookings__booking-type-item">
            <button
              @click="activeBookingType = bookingType.value"
              :class="[
                'artist-dashboard-bookings__booking-type-nav',
                { 'artist-dashboard-bookings__booking-type-nav--active': activeBookingType === bookingType.value },
              ]"
              type="button">
              {{ bookingType.label }}
            </button>
          </li>
        </ul>
        <router-link
          v-if="isArtist"
          :to="{ name: 'ArtistPayments' }">
          <Button :tag="'span'">
            {{ $t('artist.dashboard.bookings.paymentsCta') }}
          </Button>
        </router-link>
      </div>
    </header>

    <Heading
      v-if="!Object.entries(activeBookingsByMonth).length"
      :tag="'div'"
      :color="'grey'"
      :level="'h4'"
      isTextCentered
      :class="['m-auto', $mediaQueries.isDesktop ? 'p-xxl' : 'p-xl']"
      style="display: block;">
      {{ this.$t('artist.dashboard.bookings.emptyBookings') }}
    </Heading>

    <template v-else>
      <ol class="artist-dashboard-bookings__bookings-months">
        <li
          v-for="([month, bookings], index) in Object.entries(activeBookingsByMonth)"
          :key="`booking-by-month-${index}`"
          class="artist-dashboard-bookings__bookings-month">
          <Heading
            :level="'h1'"
            :tag="'h2'"
            isCapitalized
            class="artist-dashboard-bookings__bookings-month-heading">
            {{ month }}
          </Heading>
          <div
            v-if="$mediaQueries.isDesktop"
            class="artist-dashboard-bookings__timeline-line">
            <div
              v-for="(booking, index) in bookings"
              :key="`booking-timeline-${index}`"
              class="artist-dashboard-bookings__timeline-date">
              {{ new Date(booking.EventDate * 1000).getDate() }}
              <Paragraph
                :size="'sm'"
                class="artist-dashboard-bookings__timeline-date-month">
                {{ getDateAsLocalizedString({ date: new Date(booking.EventDate * 1000), options: { month: 'long' } }) }}
              </Paragraph>
            </div>
          </div>
          <ol class="artist-dashboard-bookings__bookings-list">
            <li
              v-for="(booking, index) in bookings"
              :key="`booking-${index}`"
              class="artist-dashboard-bookings__bookings-item">
              <router-link :to="{
                  name: isArtist
                    ? 'ArtistDashboardBooking'
                    : 'ClientBookingDetails',
                  params: { id: booking.ID },
                }">
                <ArtistBookingCard :booking="booking"></ArtistBookingCard>
              </router-link>
            </li>
          </ol>
        </li>
      </ol>
    </template>
  </section>
</template>

<script>

import {
  mapState,
  mapActions,
}                                 from 'vuex';

import Heading                    from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                     from '../../../components/atoms/Button/a-Button.vue';
import ArtistBookingCard          from '../../../components/molecules/ArtistBookingCard/m-ArtistBookingCard.vue';
import {
  getDateAsLocalizedString,
  isCurrentOrFutureDatetime,
}                                 from '../../../utils/dateUtils.js';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';


export default {
  name: 'o-ArtistDashboardBookings',
  components: {
    Heading,
    Paragraph,
    Button,
    ArtistBookingCard,
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeBookingType: 'upcoming',
  }),
  async created() {
    await this.getBookings();
  },
  computed: {
    ...mapState('User', ['user']),
    bookingTypes() {
      return [
        {
          label: this.$t('artist.dashboard.bookings.nav.upcoming'),
          value: 'upcoming',
        },
        {
          label: this.$t('artist.dashboard.bookings.nav.past'),
          value: 'past',
        },
        {
          label: this.$t('artist.dashboard.bookings.nav.canceled'),
          value: 'canceled',
        },
      ];
    },
    upcomingBookings() {
      return this.user?.Bookings?.filter(({ EventDate, Status }) => isCurrentOrFutureDatetime(EventDate * 1000) && (Status.toUpperCase() === AVAILABILITIES_OPTIONS.BOOKED))
        ?? [];
    },
    previousBookings() {
      return this.user?.Bookings?.filter(({ Status, EventDate }) => !isCurrentOrFutureDatetime(EventDate * 1000) && (Status.toUpperCase() === AVAILABILITIES_OPTIONS.BOOKED))
        ?? [];
    },
    canceledBookings() {
      return this.user?.Bookings?.filter(({ Status }) => Status.toUpperCase() === AVAILABILITIES_OPTIONS.CANCELED)
        ?? [];
    },
    activeBookingsByMonth() {
      switch (this.activeBookingType) {
        case 'upcoming':
          return this.getBookingsByMonth(this.sortByLessRecent(this.upcomingBookings));
        case 'past':
          return this.getBookingsByMonth(this.sortByMostRecent(this.previousBookings));
        case 'canceled':
          return this.getBookingsByMonth(this.sortByMostRecent(this.canceledBookings));
        default:
          return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getBookings: 'User/GET_BOOKINGS',
    }),
    getDateAsLocalizedString,
    getBookingsByMonth(bookings) {
      return bookings.reduce((acc, booking) => {
        const month = getDateAsLocalizedString({ date: new Date(booking.EventDate * 1000), options: { month: 'long' } });

        return { ...acc, [month]: [...(acc[month] ?? []), booking], };
      }, {});
    },
    sortByLessRecent(bookings) {
      return bookings.sort((a, b) => a.EventDate - b.EventDate);
    },
    sortByMostRecent(bookings) {
      return bookings.sort((a, b) => b.EventDate - a.EventDate);
    },
  },
}

</script>

<style lang="scss">

.artist-dashboard-bookings {
  &__header {
    display: flex;
    flex-direction: column-reverse;
    row-gap: var(--space-lg);
    padding: var(--space-md);
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-background);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
      row-gap: 0;
      padding: var(--space-lg);
    }
  }

  &__booking-types-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;

    @media screen and ($desktop) {
      justify-content: initial;
      margin-right: auto;
    }
  }

  &__booking-type-item {
    &:not(:last-child) {
      @media screen and ($desktop) {
        margin-right: var(--space-xl);
      }
    }
  }

  &__booking-type-nav {
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    color: var(--color-grey-neutral);

    &--active {
      text-decoration: underline;
      text-underline-offset: var(--space-xs);
      color: var(--color-black);
    }
  }

  &__bookings-months {
    display: flex;
    flex-direction: column;
  }

  &__bookings-month {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    grid-row-gap: var(--space-lg);
    grid-column-gap: var(--space-xxl);
    justify-items: start;

    &:not(:last-child) {
      margin-bottom: var(--space-xl);
    }

    @media screen and ($desktop) {
      grid-template-columns: 100px 1fr;
      grid-row-gap: var(--space-xl);
      justify-items: center;
    }
  }

  &__bookings-month-heading {
    grid-column: 1 / -1;
    justify-self: start;
  }

  &__timeline-line {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 2px;
    border-radius: 1px;
    background-color: var(--color-grey-semi);
  }

  &__timeline-date {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    padding-right: 1px;
    font-size: var(--text-xs);
    font-style: italic;
    border: 4px solid var(--color-white);
    border-radius: var(--rounded-circle);
    color: var(--color-white);
    background-color: var(--color-black);
  }

  &__timeline-date-month {
    position: absolute;
    left: calc(1.25 * var(--space-lg));
    color: var(--color-grey-dark);
  }

  &__bookings-list {
    justify-self: stretch;
  }

  &__bookings-item {
    &:not(:last-child) {
      margin-bottom: var(--space-md);
    }
  }
}

</style>
