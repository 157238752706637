var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-xl"},[_c('header',{staticClass:"mb-xl"},[_c('Heading',{staticClass:"mb-lg",attrs:{"tag":'h1',"level":'h3',"isCapitalized":""}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.bookings.title'))+" ")]),_c('div',{staticClass:"artist-dashboard-bookings__header"},[_c('ul',{staticClass:"artist-dashboard-bookings__booking-types-list"},_vm._l((_vm.bookingTypes),function(bookingType,index){return _c('li',{key:("booking-type-" + index),staticClass:"artist-dashboard-bookings__booking-type-item"},[_c('button',{class:[
              'artist-dashboard-bookings__booking-type-nav',
              { 'artist-dashboard-bookings__booking-type-nav--active': _vm.activeBookingType === bookingType.value } ],attrs:{"type":"button"},on:{"click":function($event){_vm.activeBookingType = bookingType.value}}},[_vm._v(" "+_vm._s(bookingType.label)+" ")])])}),0),(_vm.isArtist)?_c('router-link',{attrs:{"to":{ name: 'ArtistPayments' }}},[_c('Button',{attrs:{"tag":'span'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.bookings.paymentsCta'))+" ")])],1):_vm._e()],1)],1),(!Object.entries(_vm.activeBookingsByMonth).length)?_c('Heading',{class:['m-auto', _vm.$mediaQueries.isDesktop ? 'p-xxl' : 'p-xl'],staticStyle:{"display":"block"},attrs:{"tag":'div',"color":'grey',"level":'h4',"isTextCentered":""}},[_vm._v(" "+_vm._s(this.$t('artist.dashboard.bookings.emptyBookings'))+" ")]):[_c('ol',{staticClass:"artist-dashboard-bookings__bookings-months"},_vm._l((Object.entries(_vm.activeBookingsByMonth)),function(ref,index){
            var month = ref[0];
            var bookings = ref[1];
return _c('li',{key:("booking-by-month-" + index),staticClass:"artist-dashboard-bookings__bookings-month"},[_c('Heading',{staticClass:"artist-dashboard-bookings__bookings-month-heading",attrs:{"level":'h1',"tag":'h2',"isCapitalized":""}},[_vm._v(" "+_vm._s(month)+" ")]),(_vm.$mediaQueries.isDesktop)?_c('div',{staticClass:"artist-dashboard-bookings__timeline-line"},_vm._l((bookings),function(booking,index){return _c('div',{key:("booking-timeline-" + index),staticClass:"artist-dashboard-bookings__timeline-date"},[_vm._v(" "+_vm._s(new Date(booking.EventDate * 1000).getDate())+" "),_c('Paragraph',{staticClass:"artist-dashboard-bookings__timeline-date-month",attrs:{"size":'sm'}},[_vm._v(" "+_vm._s(_vm.getDateAsLocalizedString({ date: new Date(booking.EventDate * 1000), options: { month: 'long' } }))+" ")])],1)}),0):_vm._e(),_c('ol',{staticClass:"artist-dashboard-bookings__bookings-list"},_vm._l((bookings),function(booking,index){return _c('li',{key:("booking-" + index),staticClass:"artist-dashboard-bookings__bookings-item"},[_c('router-link',{attrs:{"to":{
                name: _vm.isArtist
                  ? 'ArtistDashboardBooking'
                  : 'ClientBookingDetails',
                params: { id: booking.ID },
              }}},[_c('ArtistBookingCard',{attrs:{"booking":booking}})],1)],1)}),0)],1)}),0)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }