<template>
  <section class="artist-booking-card__wrapper">
    <header class="artist-booking-card__header">
      <Heading
        :tag="'h3'"
        :level="'h3'"
        class="artist-booking-card__date"
        isCapitalized>
        {{ getDateAsLocalizedString({ date: new Date(booking.EventDate * 1000), locale: locale.locale }) }}
      </Heading>
      <Paragraph
        :tag="'span'"
        class="artist-booking-card__location">
        {{ booking.Line1 }},
        {{ booking.Line2 }}
        {{ booking.City }}
        {{ booking.PostalCode }}
        {{ $t('common.at')}} {{ booking.StartTime }}
      </Paragraph>
      <Heading
        :tag="'span'"
        :level="'h5'"
        :class="$mediaQueries.isDesktop ? 'ml-auto' : 'mr-auto'">
        n°{{ formattedBookingID }}
      </Heading>
      <Icon
        :variant="'arrow-right-outline'"
        :size="'xs'"
        :backdropColor="'white'"
        class="artist-booking-card__link">
      </Icon>
    </header>

    <div class="artist-booking-card__client">
      <Icon :variant="'user-outline'"></Icon>
      <template v-if="isShowBookingInfos(booking)">
        <Heading
          :tag="'h3'"
          :level="'h3'"
          isCapitalized>
          {{ bookingTarget.Firstname }}
        </Heading>
        <div class="
          artist-booking-card__client-info-item
          artist-booking-card__client-info-item--telephone
        ">
          <Icon
            :variant="'chat-outline'"
            class="ml-auto">
          </Icon>
          <Paragraph
            @click.native.stop
            :tag="'a'"
            :href="`tel:${bookingTarget.PhoneNumber}`"
            isBold>
            {{ bookingTarget.PhoneNumber }}
          </Paragraph>
        </div>
        <div class="
          artist-booking-card__client-info-item
          artist-booking-card__client-info-item--email
        ">
          <Icon
            :variant="'chat-outline'"
            class="ml-auto">
          </Icon>
          <Paragraph
            @click.native.stop
            :tag="'a'"
            :href="`mailto:${bookingTarget.Email}`"
            isBold>
            {{ bookingTarget.Email }}
          </Paragraph>
        </div>
      </template>
      <Paragraph
        v-else
        :size="'sm'"
        class="artist-booking-card__hint">
        <!-- TODO: Canceled text -->
        Les informations de votre Booker/Artiste seront disponibles un mois avant votre évènement.
      </Paragraph>
    </div>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
}                                 from 'vuex';

import Icon                       from '../../atoms/Icon/a-Icon.vue';
import Heading                    from '../../atoms/Heading/a-Heading.vue';
import Paragraph                  from '../../atoms/Paragraph/a-Paragraph.vue';
import {
  getDateAsLocalizedString,
  isCurrentOrFutureDate,
}                                 from '../../../utils/dateUtils.js';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';


export default {
  name: 'm-ArtistBookingCard',
  components: {
    Icon,
    Heading,
    Paragraph,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('User', ['locale']),
    ...mapGetters({ isArtist: 'User/IS_ARTIST_USER' }),
    bookingTarget() {
      return this.isArtist ? this.booking.Client : this.booking.Artist;
    },
    formattedBookingID() {
      return this.booking?.ID?.split('-')[0];
    },
  },
  methods: {
    getDateAsLocalizedString,
    isShowBookingInfos(booking) {
      const { Status = '', EventDate = 0 } = booking;

      return this.bookingTarget &&
        Status?.toUpperCase() === AVAILABILITIES_OPTIONS['BOOKED'] &&
        isCurrentOrFutureDate(EventDate * 1000);
    },
  },
}

</script>

<style lang="scss">

.artist-booking-card {
  &__wrapper {
    display: flex;
    flex-direction: column;
    border-radius: var(--rounded-xl);
    border: 1px solid var(--color-grey-semi);
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-row-gap: var(--space-sm);
    align-items: center;
    padding: var(--space-lg) var(--space-md);
    background-color: var(--color-grey-background);
    border-top-left-radius: var(--rounded-xl);
    border-top-right-radius: var(--rounded-xl);

    @media screen and ($desktop) {
      grid-column-gap: var(--space-xl);
      grid-template-columns: max-content max-content 1fr auto;
      padding-right: var(--space-xl);
    }
  }

  &__date {
    margin-right: var(--space-auto);
  }

  &__location {
    grid-column: 1 / -1;

    @media screen and ($desktop) {
      grid-column: 2 / 3;
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__client {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: auto auto;
    grid-gap: var(--space-base);
    align-items: center;
    padding: var(--space-lg) var(--space-md);

    @media screen and ($desktop) {
      grid-template-columns: min-content 1fr auto;
      padding-right: var(--space-xl);
    }
  }

  &__client-info-item {
    display: flex;
    align-items: center;
    column-gap: var(--space-sm);
    grid-column: 1 / -1;
    margin-right: auto;

    @media screen and ($desktop) {
      grid-column: 3 / 4;
    }

    &--telephone {
      @media screen and ($desktop) {
        grid-row: 1 / 2;
      }
    }

    &--email {
      @media screen and ($desktop) {
        grid-row: 2 / 3;
      }
    }
  }

  &__hint {
    grid-column: 1 / -1;

    @media screen and ($desktop) {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      padding: 0 var(--space-xxl);
    }
  }
}

</style>
